import React, { Component } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import ClientSlider1 from '../components/clientSlider1';
import PortfolioPaper from '../components/portfolio_paper';
// const bg = require('../images/duynhat/factory/DSC_3851_2.jpg')
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

class Flexible extends Component {


    render() {
        return (
            <>
                <Header/>
                <StaticQuery
                    query={graphql`
                    query {
                        desktop: file(relativePath: { eq: "duynhat/factory/DSC_7896.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                        }
                    }
                    `}
                    render={data => {
                        return (
                <div class="page-content bg-white">
                    <BackgroundImage
                        className="dlab-bnr-inr overlay-black-middle"
                        fluid={data.desktop.childImageSharp.fluid}
                        >
                        <div class="dlab-bnr-inr overlay-black-middle ">
                            <div class="container">
                                <div class="dlab-bnr-inr-entry">
                                    <h1 class="text-white">Paper Packaging</h1>
                                    <div class="breadcrumb-row">
                                        <ul class="list-inline">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/products">Products</Link></li>
                                            <li>Paper Packaging</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div>
                                    <h2 class="text-black font-weight-600 m-b15">Paper Packaging</h2>
                                    <p style={{marginBottom:12}}>Nowadays, there have been more concerns on protecting the environment.  So materials that are easy to recycle and decompose in a short time will be favored. This is the outstanding feature of paper packaging that few other materials rarely do.</p>
                                    <p style={{marginBottom:12}}>With a solid structure that withstands good loads, does not break during transport, there are many types and many different designs for paper packaging.In addition, paper packaging can be modified with other characteristics like waterproof, glossy…</p>
                                    <p style={{marginBottom:12}}>There are various kinds of printing to be applied to paper packaging depending on usage, quality of printing such as flexographic, offset press, roto-gravure, etc.</p>
                                     <p style={{marginBottom:12}}>Plastic packaging protects product against pasteurization or deep freezing temperatures.</p>
                                     <p style={{marginBottom:12}}> The flexo printing method is used for printing mid-range packaging products. Printing images are not as eye-catching as other technologies but lower cost, still providing sufficient information to consumers, ensuring the production in large quantities in the industry.
                                        </p>
                                        <p style={{marginBottom:40}}> The offset printing method is one of the high quality printing technologies. This method creates high definition for printed images. Common packages are found in food, agricultural &amp; consumer products.
                                        </p>
                                </div>
                                   </div>
                        </div>
                        
                        
                    </div>
                    <PortfolioPaper col="col-md-3"/>
                    <div class="section-full content-inner bg-white" style={{paddingTop:60}}>
                            <div class="container">
                            <div class="section-head text-black text-center">
                            <h2 class="title text-capitalize">Our Customers</h2>
                                <ClientSlider1
                                hideBorder
                                allowPadding
                                />
                            </div>
                            </div>
                        </div>
                </div>          
                  )
                }}
                />        
                <Footer/>                
            </>
        )
    }
}
export default Flexible;